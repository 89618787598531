import { useTranslation } from 'react-i18next';
import { wordpress, bootstrap, html, javascript, laravel, angular, react } from './../../../assets/img/logos-tecnologias';
import './pulse.css'

export default function SectionFifteen() {
    const {t} = useTranslation()
     
    return (
        <section id="tecnologias" className={`d-none ${t('technologyClass')} relative d-md-block`}>
          
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="fc-lightBlue">{t('technology')}</h1>
                        <div className="d-flex justify-content-around">
                            <div className='wordpress tecImg'>

                                <img src={wordpress} alt="WordPress" />
                            </div>
                            <div className="bootstrap tecImg">
                                <svg id="bs" xmlns="http://www.w3.org/2000/svg" width="100.693" height="80.213" viewBox="0 0 100.693 80.213">
                                    <defs>
                                        <clipPath id="clipPath">
                                            <rect id="contornoBootstrap"
                                                data-name="Rectángulo 4521"
                                                width="100.693"
                                                height="80.213"
                                                fill="#ff0000" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_5439" data-name="Grupo 5439" clipPath="url(#clipPath)">
                                        <path id="letra_B"
                                            data-name="Trazado 11743"
                                            d="M20.914,0a10.033,10.033,0,0,0-9.807,10.482c.183,5.24-.054,12.025-1.763,17.559C7.631,33.591,4.733,37.107,0,37.559v5.1c4.733.452,7.631,3.967,9.345,9.517,1.709,5.534,1.946,12.32,1.763,17.559a10.033,10.033,0,0,0,9.807,10.482H79.787a10.031,10.031,0,0,0,9.805-10.482c-.183-5.239.055-12.025,1.763-17.559,1.715-5.55,4.6-9.066,9.338-9.517v-5.1c-4.733-.452-7.624-3.967-9.338-9.517-1.708-5.533-1.945-12.32-1.763-17.559A10.032,10.032,0,0,0,79.787,0H20.914ZM68.267,49.376c0,7.51-5.6,12.064-14.9,12.064H37.547a1.707,1.707,0,0,1-1.707-1.707V20.48a1.707,1.707,0,0,1,1.707-1.707H53.28c7.751,0,12.838,4.2,12.838,10.644A9.531,9.531,0,0,1,58.336,38.7v.236c5.935.651,9.931,4.761,9.931,10.438M51.645,24.183H42.622V36.927h7.6c5.874,0,9.114-2.366,9.114-6.594,0-3.962-2.785-6.15-7.69-6.15m-9.022,17.8V56.028h9.354c6.116,0,9.356-2.454,9.356-7.066S58,41.983,51.583,41.983H42.622Z"
                                            fill="#fff" />
                                    </g>
                                </svg>

                            </div>
                            <div className="html5 tecImg">
                                <img src={html} alt="HTML5" />
                            </div>
                            <div className="javascript tecImg">
                                <img src={javascript} alt="JavaScript" />
                            </div>
                            <div className="laravel tecImg">
                                <img src={laravel} alt="Laravel" />
                            </div>
                            <div className="angular tecImg">
                                <img src={angular} alt="Angular" />
                            </div>
                            <div className="react tecImg">
                                <img src={react} alt="React" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}