import React, { useState, useEffect, useRef, useCallback } from "react";

import axios from "axios";

import success_img from "../../assets/img/contact_success.png";
import arrowForm from "./../../assets/img/icon-arrow-form.svg";
import phoneForm from "./../../assets/img/icon-phone-form.svg";
import { useInputField } from "../../customHooks/validations";

import "./ContactComponent.css";
import "flag-icon-css/css/flag-icons.min.css";

import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

import SearchCountryComponent from "../shared-components/searchCountryComponent/SearchCountryComponent";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import ReactPixel from "react-facebook-pixel";
import { GoogleReCaptchaCheckbox } from "@google-recaptcha/react";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ContactConversiones() {
  const [checkBoxes, changeCheck] = useState([
    "Quiero que mi página web se destaque",
    "Deseo actualizar mi marca",
    "Quiero aumentar mi exposición en el mercado",
    "Tengo una idea digital que quiero realizar",
  ]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([
    "Conversiones",
  ]);
  const [submitLoading, setSubmitLoader] = useState(false);

  const redirectToGracias = () => {
    window.location.href = "/gracias  "; // Redirect to the 'other' page
  };

  const [formValid, setFormValid] = useState({
    name: false,
    email: false,
    company: false,
    company_position: false,
    consulta: false,
    message: false,
    phone: false,
  });

  const buttonSubmit = useRef();
  const btnLaunchRef = useRef();
  const titleCardRef = useRef();
  const bgFormRef = useRef();
  const formRef = useRef(null);
  const cardFormRef = useRef(null);
  const sendAnimationRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState({
    nombre: "Argentina",
    phone_code: 54,
    iso2: "ar",
  });
  const [showSearch, setShowSearch] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const { t } = useTranslation();
  // form validations
  const nameInput = useInputField("", (value) => value.trim() !== "");
  const emailInput = useInputField("", (value) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  );
  const phoneInput = useInputField("", (value) => value.trim() !== "");
  const messageInput = useInputField("", (value) => value.trim() !== "");
  const companyInput = useInputField("", (value) => value.trim() !== "");
  const cargoInput = useInputField(
    "conversiones",
    (value) => value.trim() !== ""
  );

  const [token, setToken] = useState("");

  // funciones componente hijo
  const handleSearchInfo = (info) => {
    setSelectedCountry(info);
  };

  const handleChangeShow = (res) => {
    setTimeout(() => {
      setShowSearch(res);
    }, 300);
  };
  const areAllPropertiesTrue = (obj) => {
    for (let key in obj) {
      if (!obj[key]) {
        return false;
      }
    }
    return true;
  };

  const handleChangeSearch = () => {
    setShowSearch(!showSearch);
  };

  // Fin de componente hijo

  // Checkboxes

  useEffect(() => {
    setFormValid({
      name: nameInput.isValid && !nameInput.isEmpty,
      email: emailInput.isValid && !emailInput.isEmpty,
      phone: phoneInput.isValid && !phoneInput.isEmpty,
      company: companyInput.isValid && !companyInput.isEmpty,
      message: messageInput.isValid && !messageInput.isEmpty,
    });
    setDisabled(!areAllPropertiesTrue(formValid) && isVerified);
  }, [
    nameInput,
    emailInput,
    phoneInput,
    selectedCheckboxes,
    messageInput,
    companyInput,
    cargoInput,
    isVerified,
  ]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, value]);
    } else {
      setSelectedCheckboxes((prevState) =>
        prevState.filter((checkbox) => checkbox !== value)
      );
    }
  };

  const getData = () => {
    fetch("code_country.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(function (response) {
      return response.json();
    });
  };

  useEffect(() => {
    getData();
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitLoader(true);
    buttonSubmit.current.disabled = true;
    const formData = new FormData(event.target);

    const jsonData = {
      name: formData.get("name"),
      email: formData.get("email"),
      country: selectedCountry.nombre,
      company: formData.get("empresa"),
      company_position: formData.get("cargo"),
      consulta: selectedCheckboxes,
      message: formData.get("message"),
      phone: `(+${selectedCountry.phone_code})` + formData.get("phone"),
      "g-recaptcha-response": token,
    };
    if (token) {
      axios
        .post(
          "https://propuestas.chimpance.digital/api/api/Mail/Form",
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          formRef.current.style.display = "none";
          sendAnimationRef.current.style.display = "flex";
          sendAnimationRef.current.classList.remove("d-none");
          titleCardRef.current.style.display = "none !important";
          titleCardRef.current.classList.remove("d-md-block");
          bgFormRef.current.style["padding-bottom"] = "0px";

          setTimeout(() => {
            redirectToGracias();
          }, 400);

          ReactPixel.trackCustom("Contact", jsonData);
        })
        .catch((error) => {
          setSubmitLoader(false);
          buttonSubmit.current.disabled = false;
          btnLaunchRef.current.scrollIntoView({ behavior: "smooth" });
          console.log(error);
        });
    }
  }

  return (
    <div className="container" id="form" style={{ background: "transparent" }}>
      <div className="container-form-cohete2" style={{ overflow: "hidden" }}>
        <div className="container-cohete-form">
          <div className="row d-flex justify-content-center">
            <div ref={cardFormRef} className="w-100">
              <div className="bg-form px-4 py-4" ref={bgFormRef}>
                <div
                  className="containerText d-none d-md-block"
                  ref={titleCardRef}
                >
                  <p className="fs-18 ff-circularBook fc-darkBlue-01 text-center mb-4 mt-0 mt-md-3 mt-md-0 fw-bold d-md-block">
                    {t("contactForm.weRead")}
                  </p>
                </div>
                <div className="bg-form-input">
                  <p className="fs-18 ff-circularBook fc-darkBlue-01 text-center mb-4 mt-0 mt-md-3 mt-md-0 fw-bold d-block d-md-none">
                    {t("contactForm.weRead")}
                  </p>

                  <form
                    id="form-cohete"
                    method="post"
                    ref={formRef}
                    style={{ height: "auto" }}
                    onSubmit={handleSubmit}
                  >
                    {/* Name */}
                    {nameInput.isEmpty && nameInput.first && (
                      <span style={{ color: "red" }}>* Campo obligatorio</span>
                    )}
                    <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6ZM8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3Z"
                          fill="#00214B"
                        />
                        <path
                          d="M12 11C12 12 11 12 11 12H1C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11ZM11 10.9965C10.9986 10.7497 10.8462 10.0104 10.1679 9.33211C9.51563 8.67985 8.2891 8 5.99999 8C3.71088 8 2.48435 8.67985 1.8321 9.33211C1.15375 10.0104 1.00142 10.7497 1 10.9965H11Z"
                          fill="#00214B"
                        />
                      </svg>
                      <input
                        value={nameInput.value}
                        onChange={nameInput.handleChange}
                        onBlur={nameInput.handleBlur}
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder={t("contactForm.nameAndLast")}
                        required
                      />
                    </div>

                    {/* Empresa   */}
                    <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.7629 0.0746751C14.9103 0.165778 15 0.326712 15 0.500001V15.5C15 15.7761 14.7761 16 14.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14H10V15.5C10 15.7761 9.77614 16 9.5 16H0.5C0.223858 16 0 15.7761 0 15.5V10C0 9.78479 0.137715 9.59372 0.341886 9.52566L6 7.63962V4.5C6 4.31061 6.107 4.13748 6.27639 4.05279L14.2764 0.0527869C14.4314 -0.0247102 14.6155 -0.0164282 14.7629 0.0746751ZM6 8.69371L1 10.3604V15H6V8.69371ZM7 15H9V13.5C9 13.2239 9.22386 13 9.5 13H11.5C11.7761 13 12 13.2239 12 13.5V15H14V1.30902L7 4.80902V15Z"
                          fill="#00214B"
                        />
                        <path d="M2 11H3V12H2V11Z" fill="#00214B" />
                        <path d="M4 11H5V12H4V11Z" fill="#00214B" />
                        <path d="M2 13H3V14H2V13Z" fill="#00214B" />
                        <path d="M4 13H5V14H4V13Z" fill="#00214B" />
                        <path d="M8 9H9V10H8V9Z" fill="#00214B" />
                        <path d="M10 9H11V10H10V9Z" fill="#00214B" />
                        <path d="M8 11H9V12H8V11Z" fill="#00214B" />
                        <path d="M10 11H11V12H10V11Z" fill="#00214B" />
                        <path d="M12 9H13V10H12V9Z" fill="#00214B" />
                        <path d="M12 11H13V12H12V11Z" fill="#00214B" />
                        <path d="M8 7H9V8H8V7Z" fill="#00214B" />
                        <path d="M10 7H11V8H10V7Z" fill="#00214B" />
                        <path d="M12 7H13V8H12V7Z" fill="#00214B" />
                        <path d="M8 5H9V6H8V5Z" fill="#00214B" />
                        <path d="M10 5H11V6H10V5Z" fill="#00214B" />
                        <path d="M12 5H13V6H12V5Z" fill="#00214B" />
                        <path d="M12 3H13V4H12V3Z" fill="#00214B" />
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="empresa"
                        id="empresa"
                        placeholder={t("contactForm.company")}
                        required
                        value={companyInput.value}
                        onChange={companyInput.handleChange}
                        onBlur={companyInput.handleBlur}
                      />
                    </div>

                    {/* Cargo   */}
                    <div className="input-group d-none input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                      >
                        <path
                          d="M6.5 0C5.67173 0 5 0.671169 5 1.49976V2H1.5C0.671573 2 0 2.67157 0 3.5V11.5C0 12.3284 0.671573 13 1.5 13H14.5C15.3284 13 16 12.3284 16 11.5V3.5C16 2.67157 15.3284 2 14.5 2H11V1.49976C11 0.671169 10.3283 0 9.5 0H6.5ZM6.5 1H9.5C9.7763 1 10 1.22463 10 1.50061V2H6V1.49976C6 1.22377 6.2237 1 6.5 1ZM8.38649 7.91441L15 6.15081V11.5C15 11.7761 14.7761 12 14.5 12H1.5C1.22386 12 1 11.7761 1 11.5V6.15081L7.61351 7.91441C7.86674 7.98194 8.13326 7.98194 8.38649 7.91441ZM1.5 3H14.5C14.7761 3 15 3.22386 15 3.5V5.11586L8.12883 6.94817C8.04442 6.97068 7.95558 6.97068 7.87117 6.94817L1 5.11586V3.5C1 3.22386 1.22386 3 1.5 3Z"
                          fill="#00214B"
                        />
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="cargo"
                        id="cargo"
                        placeholder="Puesto"
                        value={cargoInput.value}
                        onChange={cargoInput.handleChange}
                        onBlur={cargoInput.handleBlur}
                        required
                      />
                    </div>

                    {/* Emaill */}
                    {(!emailInput.isValid || emailInput.isEmpty) &&
                      emailInput.first && (
                        <span style={{ color: "red" }}>
                          * Campo obligatorio y debe ser un mail válido
                        </span>
                      )}
                    <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M15.2 7.05572C15.2 2.89515 12.1283 0 7.71204 0C3.1371 0 0 3.24985 0 7.99521C0 12.8939 3.05307 16 7.87076 16C9.04717 16 10.1769 15.8274 11.0172 15.5303V14.3223C10.429 14.6004 9.16855 14.7825 7.88943 14.7825C3.89337 14.7825 1.31646 12.1462 1.31646 8.05273C1.31646 4.06471 3.94005 1.20791 7.59066 1.20791C11.3253 1.20791 13.8835 3.57579 13.8835 7.03655C13.8835 9.39485 13.1273 10.9191 11.9509 10.9191C11.2786 10.9191 10.8958 10.5261 10.8958 9.855V4.22768H9.486V5.39724H9.33661C8.97248 4.56321 8.13219 4.04554 7.15184 4.04554C5.23784 4.04554 3.9027 5.67525 3.9027 8.00479C3.9027 10.4398 5.20983 12.0791 7.15184 12.0791C8.24423 12.0791 9.08452 11.5327 9.486 10.5548H9.63538C9.74742 11.4943 10.5971 12.1654 11.6894 12.1654C13.8369 12.1654 15.2 10.1809 15.2 7.05572ZM5.39656 8.04314C5.39656 6.36549 6.13415 5.36848 7.38526 5.36848C8.65504 5.36848 9.46732 6.40383 9.46732 8.04314C9.46732 9.68244 8.6457 10.7274 7.36658 10.7274C6.14349 10.7274 5.39656 9.7112 5.39656 8.04314Z"
                          fill="#00214B"
                        />
                      </svg>
                      <input
                        value={emailInput.value}
                        onChange={emailInput.handleChange}
                        onBlur={emailInput.handleBlur}
                        type="email"
                        className="form-control"
                        name="email"
                        id="mail"
                        placeholder={t("contactForm.email")}
                        required
                      />
                    </div>

                    {/* telefono */}
                    <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                      <div className="row" style={{ width: "110%" }}>
                        <div className="col-md-3 col-5 d-flex align-items-end">
                          {selectedCountry ? (
                            <div className="position-relative country_char">
                              <div
                                onClick={handleChangeSearch}
                                className="text-center fw-bold fc-darkBlue  position-relative"
                              >
                                <span
                                  className={`flag-icon me-2 flag-icon-${selectedCountry.iso2
                                    .toString()
                                    .toLowerCase()} px-2`}
                                ></span>
                                <span className="ps-2">
                                  (+{selectedCountry.phone_code})
                                </span>
                              </div>
                              {showSearch ? (
                                <div className="searchComponent">
                                  <SearchCountryComponent
                                    showSearch={showSearch}
                                    onChangeShow={handleChangeShow}
                                    onInfoSended={handleSearchInfo}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-9 col-7 telefono position-relative">
                          {phoneInput.isEmpty && phoneInput.first && (
                            <label style={{ color: "red" }}>
                              * Campo obligatorio
                            </label>
                          )}
                          <div className="input-group input-group-lg  position-relative d-flex align-items-end">
                            <svg
                              width="19"
                              height="19.012"
                              viewBox="0 0 19 19.012"
                              style={{
                                verticalAlign: "sub",
                                margin: "10px 7px",
                              }}
                            >
                              <defs>
                                <clipPath id="clipPath">
                                  <rect
                                    id="Rectángulo_4335"
                                    data-name="Rectángulo 4335"
                                    width="19"
                                    height="19.012"
                                    fill="#005081"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Grupo_5255"
                                data-name="Grupo 5255"
                                transform="translate(-3.358 -2.944)"
                              >
                                <g
                                  id="Grupo_4709"
                                  data-name="Grupo 4709"
                                  transform="translate(3.358 2.944)"
                                  clipPath="url(#clipPath)"
                                >
                                  <path
                                    id="Trazado_17770"
                                    data-name="Trazado 17770"
                                    d="M14.245,19.012a9.542,9.542,0,0,1-2.489-.582,17.449,17.449,0,0,1-5-2.956A21.559,21.559,0,0,1,1.527,9.311,12.475,12.475,0,0,1,.1,5.558C.062,5.351.041,5.14.009,4.932a.981.981,0,0,1,.167-.638A13.7,13.7,0,0,1,2.137,1.645,17.348,17.348,0,0,1,3.56.3,1.107,1.107,0,0,1,5.266.547,22.583,22.583,0,0,1,7.631,4.423a4.916,4.916,0,0,1,.216.614.955.955,0,0,1-.2.962c-.228.254-.486.482-.737.714-.174.161-.354.317-.542.462a.212.212,0,0,0-.074.286,8.64,8.64,0,0,0,3.2,4.191,8.525,8.525,0,0,0,2.047,1.061.215.215,0,0,0,.286-.072q.329-.408.682-.8a4.958,4.958,0,0,1,.523-.509.905.905,0,0,1,.911-.18,8.19,8.19,0,0,1,1.357.581c1.036.615,2.046,1.276,3.06,1.927a1.246,1.246,0,0,1,.354.34,1.012,1.012,0,0,1,.052,1.347,8.231,8.231,0,0,1-1.406,1.522,14.574,14.574,0,0,1-2.654,1.955,3.37,3.37,0,0,1-.464.185M4.282,1.3c-.066.055-.1.079-.125.1A17.857,17.857,0,0,0,1.465,4.531a.871.871,0,0,0-.139.716A10.925,10.925,0,0,0,2.516,8.479a20.311,20.311,0,0,0,7.469,7.709,10.788,10.788,0,0,0,4.021,1.521.427.427,0,0,0,.289-.065c.716-.538,1.441-1.067,2.13-1.639.453-.377.851-.82,1.286-1.245-.1-.078-.165-.133-.234-.183a23.349,23.349,0,0,0-3.5-2.081.312.312,0,0,0-.456.094c-.384.451-.791.884-1.187,1.325a.574.574,0,0,1-.636.165,8.847,8.847,0,0,1-2.931-1.39A9.606,9.606,0,0,1,5.023,7.616c-.236-.753-.281-.6.306-1.2.365-.37.776-.695,1.17-1.035a.181.181,0,0,0,.053-.266c-.43-.778-.837-1.57-1.285-2.337-.295-.5-.646-.977-.985-1.485"
                                    transform="translate(0 0)"
                                    fill="#005081"
                                  />
                                </g>
                              </g>
                            </svg>

                            <input
                              value={phoneInput.value}
                              onChange={phoneInput.handleChange}
                              onBlur={phoneInput.handleBlur}
                              type="number"
                              className="form-control"
                              id="phone"
                              name="phone"
                              placeholder={t("contactForm.phone")}
                              required
                            />
                          </div>
                          <span></span>
                        </div>
                      </div>
                    </div>

                    {/* Preguntas Checkbox */}
                    <div className=" mt-3 checkbox_preguntas d-none">
                      <p className="pregunta">¿Cómo podemos ayudarte?</p>
                      <FormGroup className="checkboxes">
                        {checkBoxes.map((checkbox, index) => (
                          <div className={index} key={index + "index"}>
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={selectedCheckboxes.includes(
                                    checkbox
                                  )}
                                  onChange={handleCheckboxChange}
                                  value={checkbox}
                                />
                              }
                              label={checkbox}
                            />
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    {/* Message */}
                    <div
                      className="custom_consulta input-group input-group-lg pt-4 mb-4 position-relative  d-flex align-items-baseline campos"
                      id="select2"
                    >
                      <textarea
                        value={messageInput.value}
                        onChange={messageInput.handleChange}
                        onBlur={messageInput.handleBlur}
                        name="message"
                        id="message"
                        className="bg-transparent border-0 form-control "
                        cols="30"
                        rows="1"
                        placeholder={t("contactForm.message")}
                      ></textarea>
                    </div>
                    <GoogleReCaptchaCheckbox
                      onChange={(token) => {
                        setToken(token);
                      }}
                    />

                    {/* Send */}
                    <div className="d-grid gap-2 pt-3">
                      <button
                        type="submit"
                        className="btn btn-send"
                        ref={buttonSubmit}
                        disabled={disabled}
                      >
                        {submitLoading ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress
                              sx={{
                                color: "#E8F0FB",
                              }}
                            />
                          </Box>
                        ) : (
                          <span className="sendText">
                            {t("contactForm.send")}
                            <svg
                              className="ms-3"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                id="send"
                                d="M19.122,11.447,5.388,4.571a.618.618,0,0,0-.88.7L6.355,12,4.483,18.717a.636.636,0,0,0,.905.719L19.122,12.56a.625.625,0,0,0,0-1.112ZM6.075,17.7l1.379-5.07h5.768v-1.25H7.454L6.075,6.309,17.442,12Z"
                                transform="translate(-4.461 -4.505)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>

                  <div
                    id="contact_form"
                    className="align-items-center d-none fc-darkBlue-01 flex-column justify-content-center "
                    ref={sendAnimationRef}
                  >
                    <h4 className="fs1">{t("contactForm.success")}</h4>
                    <p>
                      {t("contactForm.weContact")}{" "}
                      <strong>{t("contactForm.evolve")}</strong>
                    </p>
                    <img src={success_img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="box-form"></div>
    </div>
  );
}
